.wholepage: {
    background: linear-gradient(45deg, rgba(29, 236, 197, 0.5), rgba(91, 14, 214, 0.5) 100%);
}

.imageHover:hover {
    color: #ff0000;
    transform: scale(1.1);
}

.uzoxtext:hover {
    color: #ff0000;
    transform: scale(1.1);
}